import React, { PureComponent } from 'react'
import { array, bool, func, number, object, string } from 'prop-types'
import styled from '@emotion/styled'
import { css } from 'emotion'

import IconDelivery from 'microcomponents/icon-delivery'
import IconDrivers from 'microcomponents/icon-drivers'
import IconCases from 'microcomponents/icon-cases'
import IconDepotCase from 'microcomponents/icon-depot-case'
import IconPacker from 'microcomponents/icon-packer'
import IconRunner from 'microcomponents/icon-runner'
import { RestockIcon } from 'microcomponents/icons'
import IconSettings from 'microcomponents/icon-settings'
import { DASHBOARD_NAMES } from 'helpers/dashboard'
import NavButtonGroup from 'src/components/navigation-button-group'
import NavButton from 'src/components/navigation-button'
import { getDispTreatment } from '../../helpers/split-client'
import { SPLIT_EXPERIMENTS, SPLIT_TREATMENTS } from '../../helpers/constants.js'

export default class DashboardContainer extends PureComponent {
  static propTypes = {
    children: object,
    selectedDepot: object,
    depotId: number,
    getDepotRoute: func,
    hubAndSpokeDepotIds: array,
    isAdmin: bool,
    tab: string,
    task: string
  }

  state = {
    dashboardName: DASHBOARD_NAMES.inventory
  }

  isActive = (type) => {
    return (
      this.props.tab === type || `${this.props.tab}/${this.props.task}` === type
    )
  }

  getDashboardName = async (dispensaryId) => {
    const experiment = SPLIT_EXPERIMENTS.SERVICE_AREA_DRIVER
    const treatmentOn = SPLIT_TREATMENTS.DISPENSARY_CASE.ON
    const treatment = await getDispTreatment(dispensaryId, experiment)
    if (treatment[SPLIT_EXPERIMENTS.SERVICE_AREA_DRIVER] === treatmentOn) {
      DASHBOARD_NAMES.drivers.label = 'Dispo Drivers'
      DASHBOARD_NAMES.inventory.label = 'Depot Case'
    } else {
      DASHBOARD_NAMES.drivers.label = 'Depot Drivers'
      DASHBOARD_NAMES.inventory.label = 'Depot Case'
    }
    this.setState({ dashboardName: DASHBOARD_NAMES.drivers })
    this.setState({ dashboardName: DASHBOARD_NAMES.inventory })
  }

  componentDidMount () {
    const { selectedDepot } = this.props
    const dispensaryId = selectedDepot.dispensaryId
    this.getDashboardName(dispensaryId)
  }

  render () {
    const {
      children,
      getDepotRoute,
      depotId,
      hubAndSpokeDepotIds,
      isAdmin
    } = this.props

    const showHubAndSpoke = hubAndSpokeDepotIds.includes(depotId)

    return (
      <Container>
        <NavContainer>
          <NavButtonGroup ComponentStyle={NavbarStyle}>
            <NavButton
              icon={IconDelivery}
              isActive={this.isActive(DASHBOARD_NAMES.deliveries.name)}
              label={DASHBOARD_NAMES.deliveries.label}
              route={getDepotRoute(DASHBOARD_NAMES.deliveries.name)}
            />
            <NavButton
              icon={IconDrivers}
              isActive={this.isActive(DASHBOARD_NAMES.drivers.name)}
              label={DASHBOARD_NAMES.drivers.label}
              route={getDepotRoute(DASHBOARD_NAMES.drivers.name)}
            />
            <NavButton
              icon={IconCases}
              isActive={this.isActive(DASHBOARD_NAMES.cases.name)}
              label={DASHBOARD_NAMES.cases.label}
              route={getDepotRoute(DASHBOARD_NAMES.cases.name)}
            />
            {showHubAndSpoke && (
              <>
                <NavButton
                  icon={IconDepotCase}
                  isActive={this.isActive(this.state.dashboardName.name)}
                  label={this.state.dashboardName.label}
                  route={getDepotRoute(this.state.dashboardName.name)}
                />
                <NavButton
                  icon={IconPacker}
                  isActive={this.isActive(DASHBOARD_NAMES.packer.name)}
                  label={DASHBOARD_NAMES.packer.label}
                  route={getDepotRoute(DASHBOARD_NAMES.packer.name)}
                />
                <NavButton
                  icon={IconRunner}
                  isActive={this.isActive(DASHBOARD_NAMES.runner.name)}
                  label={DASHBOARD_NAMES.runner.label}
                  route={getDepotRoute(DASHBOARD_NAMES.runner.name)}
                />
                <NavButton
                  icon={RestockIcon}
                  isActive={this.isActive(DASHBOARD_NAMES.canceled.name)}
                  label={DASHBOARD_NAMES.canceled.label}
                  route={getDepotRoute(DASHBOARD_NAMES.canceled.name)}
                />
              </>
            )}
            {isAdmin && (
              <NavButton
                icon={IconSettings}
                isActive={this.isActive(DASHBOARD_NAMES.settings.name)}
                label={DASHBOARD_NAMES.settings.label}
                route={getDepotRoute(DASHBOARD_NAMES.settings.name)}
              />
            )}
          </NavButtonGroup>
        </NavContainer>
        <Content>{children}</Content>
      </Container>
    )
  }
}

const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`
const Content = styled.div`
  flex: 1;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`

const NavContainer = styled.div`
  flex: 0;
  padding: 1rem;
`

const NavbarStyle = css`
  flex-direction: column;
`
